import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { css } from 'styled-components'

import { FAQJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'

import { space, mediaQueries, boxHeights } from '../utils/tokens'

import RichArticle from '../components/RichArticle'
import Container from '../components/Container'
import { HeroSelector } from '../components/HeroSelector'
import { SectionSelector } from '../components/SectionSelector'
import { FAQSection } from '../components/Section'

import siteConfig from '../utils/siteConfig'
import { MainLayout } from '../components/MainLayout'
import { faqPageSchemaGenerator } from '../utils/faqPageSchemaGenerator'


const PageTemplate = ({data}) => {
  const {
    title,
    fields,
    hero,
    metaDescription,
    metaTitle,
    metaImage,
    body,
    sections,
    frequentlyAskedQuestions,
    schemaOrgJSONLD,
  } = data.contentfulPage

  const sanitizedFaqs =
    frequentlyAskedQuestions?.length > 0 &&
    frequentlyAskedQuestions.map(faq => {
      return {
        question: faq.question,
        answer: faq.answer && faq.answer.childMarkdownRemark.html,
      }
    })

  const faqSchema = faqPageSchemaGenerator(sanitizedFaqs)

  const ogImages = metaImage && [
    {
      url: `https:${metaImage.file?.url}`,
      alt: metaImage.title,
      width: 1200,
      height: 627,
    },
  ]

  const isLandingPage = fields.path === `/`
  
  return (
    <div>
      <MainLayout headerTheme={`light`}>
        <Helmet>
          {schemaOrgJSONLD && (
            <script type="application/ld+json">
              {schemaOrgJSONLD.internal.content}
            </script>
          )}
          {faqSchema && (
            <script type="application/ld+json">
              {JSON.stringify(faqSchema)}
            </script>
          )}
          {isLandingPage && (
            // for pinterest
            <meta
              name="p:domain_verify"
              content="0a67397cacaf45d3a91f957bd0b59eaf"
            />
          )}
          {isLandingPage && (
            <link
              rel="preconnect dns-prefetch"
              href="https://marketingplatfom.google.com"
            />
          )}
          {isLandingPage && (
            <link rel="preconnect dns-prefetch" href="https://www.google.com" />
          )}
        </Helmet>
        <GatsbySeo
          title={metaTitle || `${title} | PrintAWorld`}
          description={metaDescription?.internal?.content}
          openGraph={{
            url: `${siteConfig.siteUrl}${fields.path}`,
            title: metaTitle || `${title} | PrintAWorld`,
            description: metaDescription?.internal?.content,
            images: ogImages,
          }}
        />
        <main
          css={css({
            paddingTop: title === `Home` ? 0 : `${space[6]}px`,
            [mediaQueries.lg]: {
              paddingTop: 0,
            },
          })}
        >
          <HeroSelector
            hero={hero}
            height={`calc(100vh - ${boxHeights.plainHeader})`}
          />
          {sections && <SectionSelector sections={sections} />}
          {body && (
            <Container
              css={css({
                padding: `${space[5]}px calc(${space[3]}px + ${space[2]}px)`,
                [mediaQueries.lg]: {
                  padding: `${space[5]}px 0`,
                },
              })}
            >
              <RichArticle body={body} />
            </Container>
          )}
          {frequentlyAskedQuestions && (
            <FAQSection faqs={frequentlyAskedQuestions} />
          )}
        </main>
      </MainLayout>
    </div>
  )
}

export const query = graphql`
  query pageQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      fields {
        path
      }
      metaTitle
      metaImage {
        title
        fixed(width: 1200, height: 627, quality: 80) {
          src
        }
        file {
          url
        }
      }
      schemaOrgJSONLD {
        internal {
          content
        }
      }
      hero {
        ... on Node {
          __typename
          ... on ContentfulHeroWithBlock {
            ...HeroWithBlock
          }
          ... on ContentfulHeroTextOnly {
            ...HeroTextOnly
          }
          ... on ContentfulHero {
            ...HeroOld
          }
        }
      }
      sections {
        ... on Node {
          ...SectionsFragment
        }
      }
      body {
        raw
        references {
          ...RichTextArticleFragment
        }
      }
      metaDescription {
        internal {
          content
        }
      }
      frequentlyAskedQuestions {
        id
        question
        answer {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default PageTemplate