export const faqPageSchemaGenerator = (faqs = []) => {
  // must be an array of object with this shap
  // { question: 'hello, answer: 'world }
  if(faqs.length > 0) {
    const faqSchemaList = faqs.map((faq) => {
      return {
        '@type': 'Question',
        name: faq.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq.answer
        },
      }
    })

    return {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: faqSchemaList,
    }
  } else {
    return null
  }
}